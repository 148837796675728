export const DEFAULT_COLUMN_GAP_PX = 16
export const DEFAULT_CELL_WIDTH_PX = 128
export const DEFAULT_OVERSCAN_BY = 1
export const DEFAULT_HEADER_HEIGHT_PX = 40
export const DEFAULT_ITEM_HEIGHT_PX = 80

/**
 * Default number of items before the end of the items list to start loading the
 * next page of items.
 */
export const DEFAULT_PAGINATION_THRESHOLD = 6
