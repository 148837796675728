import React, { memo, useMemo } from "react"
import { breakpoints, Flex, Text } from "@opensea/ui-kit"
import styled from "styled-components"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { Z_INDEX } from "@/constants/zIndex"
import { Block } from "@/design-system/Block"
import { FeatureTable } from "@/design-system/FeatureTable"
import { VirtualizedTable } from "@/design-system/VirtualizedTable"
import { useIsVirtualizedTableViewEnabled } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"

type Config = {
  label: string
  width: string
  minWidth: string
  justifyContent: string
  flexShrink?: number
}

export const COLUMN_MIN_WIDTH = "100px"
export const COLUMN_PRIMARY_MIN_WIDTH = "140px"
export const COLUMN_SPACER_WIDTH = "60px"
export const COLUMN_SPACER_MIN_WIDTH = "40px"

export const getAssetSearchViewTableColumnWidth = (
  isCollectionRarityEnabled: boolean,
  isVirtualizedTableViewEnabled: boolean,
) => {
  let numColumns = 5
  if (isCollectionRarityEnabled) {
    numColumns += 1
  }
  if (isVirtualizedTableViewEnabled) {
    return `${66 / numColumns}%`
  }
  return `${70 / numColumns}%`
}

const _AssetSearchListViewTableHeader = () => {
  const t = useTranslate("assets")
  const isVirtualizedTableViewEnabled = useIsVirtualizedTableViewEnabled()
  const { contentTopOffset, isCollectionRarityEnabled } =
    useAccountOrCollectionPageContext()

  const columnsWidth = getAssetSearchViewTableColumnWidth(
    isCollectionRarityEnabled,
    false,
  )

  const columns = useMemo(
    () =>
      [
        {
          label: "",
          width: COLUMN_SPACER_WIDTH,
          minWidth: COLUMN_SPACER_MIN_WIDTH,
          justifyContent: "flex-start",
          flexShrink: 1,
        },
        {
          label: t("assets.columnHeader.item", "Item"),
          width: "25%",
          minWidth: COLUMN_PRIMARY_MIN_WIDTH,
          justifyContent: "flex-start",
        },
        {
          label: t("assets.columnHeader.currentPrice", "Current Price"),
          width: columnsWidth,
          minWidth: COLUMN_PRIMARY_MIN_WIDTH,
          justifyContent: "flex-start",
        },
        {
          label: t("assets.columnHeader.bestOffer", "Best Offer"),
          width: columnsWidth,
          minWidth: COLUMN_MIN_WIDTH,
          justifyContent: "flex-start",
        },
        {
          label: t("assets.columnHeader.lastSale", "Last Sale"),
          width: columnsWidth,
          minWidth: COLUMN_MIN_WIDTH,
          justifyContent: "flex-start",
        },
        isCollectionRarityEnabled
          ? {
              label: t("assets.columnHeader.rarityRank", "Rarity"),
              width: columnsWidth,
              minWidth: COLUMN_MIN_WIDTH,
              justifyContent: "flex-start",
            }
          : undefined,
        {
          label: t("assets.columnHeader.owner", "Owner"),
          width: columnsWidth,
          minWidth: COLUMN_MIN_WIDTH,
          justifyContent: "flex-start",
        },
        {
          label: t("assets.columnHeader.timeListed", "Time Listed"),
          width: columnsWidth,
          minWidth: COLUMN_MIN_WIDTH,
          justifyContent: "flex-start",
        },
      ].filter((config): config is Config => !!config),
    [t, columnsWidth, isCollectionRarityEnabled],
  )

  if (isVirtualizedTableViewEnabled) {
    return (
      <Header $top={contentTopOffset}>
        {columns.map(({ label, ...rest }, idx) => (
          <VirtualizedTable.Row.Cell key={idx} {...rest}>
            <Text.Body size="small" weight="semibold">
              <Wrapper>{label}</Wrapper>
            </Text.Body>
          </VirtualizedTable.Row.Cell>
        ))}
      </Header>
    )
  }

  return (
    <Flex
      className="w-full border-b border-b-level-2 bg-base-1 px-4 pb-6 pt-2"
      style={{ zIndex: Z_INDEX.ASSET_SEARCH_LIST_TABLE_HEADER }}
    >
      {columns.map(({ label, ...rest }, idx) => (
        <FeatureTable.Cell key={idx} {...rest}>
          <Text.Body className="text-secondary" size="small">
            <Wrapper>{label}</Wrapper>
          </Text.Body>
        </FeatureTable.Cell>
      ))}
    </Flex>
  )
}

export const AssetSearchListViewTableHeader = memo(
  _AssetSearchListViewTableHeader,
)

const Wrapper = styled(Block)`
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Header = styled(VirtualizedTable.Header)<{ $top: number }>`
  padding: 8px 16px 24px; // Match row left and right padding

  @media (min-width: ${breakpoints.xl}px) {
    padding: 16px 16px 32px;
  }
  z-index: ${Z_INDEX.ASSET_SEARCH_LIST_TABLE_HEADER};
`
