import React, { CSSProperties } from "react"
import { useFragment } from "react-relay"
import { Price_data$key } from "@/lib/graphql/__generated__/Price_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { FiatPrice } from "./price/FiatPrice.react"
import { TokenPrice } from "./price/TokenPrice.react"

export type PriceProps = {
  className?: string
  data: Price_data$key
  variant?: "token" | "fiat"
  secondary?: boolean
  quantity: BigNumber
  symbolVariant?: "raw" | "avatar" | "both"
  maxDecimalPlaces?: number
  fontWeight?: CSSProperties["fontWeight"]
  position?: CSSProperties["position"]
  compactDisplay?: boolean
  noTooltip?: boolean
}

export const Price = ({
  className,
  data: dataKey,
  quantity,
  variant = "token",
  secondary,
  symbolVariant = "avatar",
  maxDecimalPlaces,
  fontWeight,
  position,
  compactDisplay,
  noTooltip = false,
}: PriceProps) => {
  const { decimals, symbol, usdSpotPrice } = useFragment(
    graphql`
      fragment Price_data on AssetType {
        decimals
        symbol
        usdSpotPrice
      }
    `,
    dataKey,
  )

  const price = bn(quantity, decimals)
  switch (variant) {
    case "fiat": {
      if (usdSpotPrice === null) {
        return null
      }
      const fiatPrice = price.times(usdSpotPrice)
      return (
        <FiatPrice
          className={className}
          fontWeight={fontWeight}
          position={position}
          price={fiatPrice}
          secondary={secondary}
        />
      )
    }
    case "token": {
      return (
        <TokenPrice
          className={className}
          compactDisplay={compactDisplay}
          fontWeight={fontWeight}
          maxDecimalPlaces={maxDecimalPlaces}
          noTooltip={noTooltip}
          position={position}
          price={price}
          symbol={symbol ?? ""}
          symbolVariant={symbolVariant}
        />
      )
    }
    default:
      throw new UnreachableCaseError(variant)
  }
}
