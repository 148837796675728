export const generateSizes = (
  breakpoints: [string, string][],
  defaultSize: string,
) => {
  /*
    Breakpoints should be in ascending order, the first member of the tuple is the breakpoint, the second is the image size to be fetched.
    The defaultSize is the number of columns to use if the viewport is larger than the largest breakpoint.
  */

  const sizes = breakpoints.reduce((acc, current) => {
    const [breakpoint, width] = current
    return `${acc}(max-width: ${breakpoint}) ${width}, `
  }, "")
  return `${sizes}${defaultSize}`
}

export const getColumnWidth = (columnCount: number): string => {
  return `${100 / columnCount}vw`
}

type BreakpointColumnsSizesConfig = {
  sizes: [string, number][]
  defaultColumns: number
}

export const generateSizesFromBreakpointColumnsConfig = ({
  sizes,
  defaultColumns,
}: BreakpointColumnsSizesConfig) => {
  // Sizes is an array of tuples, the first member of the tuple is the breakpoint, the second is the number of columns to use at that breakpoint
  // The image will be fetched at the size of 1 column
  // This returns a string that can be used as the sizes attribute on an Image component
  const breakpoints = sizes.map(([breakpoint, columns]): [string, string] => [
    breakpoint,
    getColumnWidth(columns),
  ])

  const defaultWidth = getColumnWidth(defaultColumns)

  return generateSizes(breakpoints, defaultWidth)
}
