import React, { forwardRef, MouseEventHandler } from "react"
import styled, { css } from "styled-components"
import { Block, BlockProps } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { VirtualizedTableCell } from "@/design-system/VirtualizedTable/components/VirtualizedTableCell.react"
import { DEFAULT_COLUMN_GAP_PX } from "@/design-system/VirtualizedTable/constants"

type Props = {
  className?: string
  children: React.ReactNode
  gap?: number
  /**
   * Minimum height of the row required to ensure virtualization works properly.
   * Should match `itemHeightEstimate.min` passed into `VirtualizedTable`.
   */
  minHeight: number
  more?: React.ReactNode
  showHover?: boolean
  onClick?: MouseEventHandler<HTMLElement>
  onMouseEnter?: MouseEventHandler<HTMLElement>
  onMouseLeave?: MouseEventHandler<HTMLElement>
} & Omit<BlockProps, "minHeight">

const VirtualizedTableRowBase = forwardRef<HTMLDivElement, Props>(
  function VirtualizedTableRowBase(
    {
      alignItems = "center",
      className,
      children,
      gap = DEFAULT_COLUMN_GAP_PX,
      minHeight,
      more,
      showHover = false,
      onClick,
      ...blockProps
    }: Props,
    ref,
  ) {
    return (
      <Container
        aria-expanded={!!more}
        className={className}
        minHeight={minHeight}
        ref={ref}
        role="row"
        {...blockProps}
      >
        <CellsContainer
          $expanded={!!more}
          $interactive={!!onClick}
          $showHover={showHover}
          alignItems={alignItems}
          gap={gap}
          height={minHeight}
          onClick={onClick}
        >
          {children}
        </CellsContainer>
        {more && <ExpandedContainer>{more}</ExpandedContainer>}
      </Container>
    )
  },
)

export const VirtualizedTableRow = Object.assign(VirtualizedTableRowBase, {
  Cell: VirtualizedTableCell,
})

const Container = styled(Block)`
  min-width: fit-content;
  width: 100%;
`

const CellsContainer = styled(Flex)<{
  $expanded: boolean
  $interactive: boolean
  $showHover: boolean
  gap?: number
}>`
  // Ensure container spans the entire width of its contents to ensure sticky
  // columns do not lose stickiness on small viewports.
  display: inline-flex;
  min-width: 100%;

  ${props =>
    props.gap &&
    css`
      gap: ${props.gap}px;
    `}

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  ${props =>
    (props.$interactive || props.$showHover) &&
    css`
      :hover {
        background-color: ${props =>
          props.theme.colors.components.background.gray1};
        & > * {
          // Apply on-hover background to all children to ensure sticky cells
          // have the same hover background since they need a non-transparent
          // background to avoid showing content behind them when scrolling.
          // Background color cannot have opacity to avoid showing content
          // behind sticky cells.
          background-color: ${props =>
            props.theme.type === "light" ? "#f4f4f4" : "#1b1b1b"};
        }
      }
    `}

  ${props =>
    props.$interactive &&
    css`
      :active {
        background-color: ${props =>
          props.theme.colors.components.background.gray2};

        & > * {
          background-color: transparent;
        }
      }
    `}

  ${props =>
    props.$expanded
      ? css`
          background-color: ${props.theme.colors.components.background.gray1};
          border-top-left-radius: ${props => props.theme.borderRadius.large};
          border-top-right-radius: ${props => props.theme.borderRadius.large};

          & > * {
            background-color: transparent;
            border-top-left-radius: ${props => props.theme.borderRadius.large};
            border-top-right-radius: ${props => props.theme.borderRadius.large};
          }
        `
      : css`
          :hover {
            border-radius: ${props => props.theme.borderRadius.large};
            & > * {
              border-radius: ${props => props.theme.borderRadius.large};
            }
          }
        `}
`

const ExpandedContainer = styled(Flex)`
  height: 100%;
  border-bottom-left-radius: ${props => props.theme.borderRadius.large};
  border-bottom-right-radius: ${props => props.theme.borderRadius.large};
  background-color: ${props =>
    props.theme.colors.components.elevation.level3.background};
`
