/* istanbul ignore file */
import React from "react"
import { useRarity } from "@/components/search/rarity/RarityIndicator.react"
import { RarityIndicator_data$key } from "@/lib/graphql/__generated__/RarityIndicator_data.graphql"

type Props = {
  dataKey?: RarityIndicator_data$key | null
}

export const AssetSearchListViewTableRarityInfo = ({ dataKey }: Props) => {
  const { data } = useRarity({ dataKey })

  if (data) {
    return <>#&nbsp;{data.rank}</>
  }

  return <span>&mdash;</span>
}
