/* istanbul ignore file */
import React, { ComponentProps, memo, useCallback, useRef } from "react"
import {
  classNames,
  useIsLessThanLg,
  Checkbox,
  Flex,
  Skeleton,
} from "@opensea/ui-kit"
import {
  commitLocalUpdate,
  useFragment,
  useRelayEnvironment,
} from "react-relay"
import { useUpdateEffect } from "react-use"
import {
  ItemCardFragment,
  ItemCardOnClickExtra,
} from "@/components/assets/ItemCard.react"
import { ItemCardFooter } from "@/components/assets/ItemCardFooter.react"
import { FILTER_BAR_HEIGHT } from "@/components/layout/AccountOrCollectionPage/constants"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { useActiveAccount } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import {
  FeatureTable,
  FeatureTableRenderProps,
} from "@/design-system/FeatureTable"
import { Tooltip } from "@/design-system/Tooltip"
import { COLLECTION_FOOTER_HEIGHT } from "@/features/collections/components/CollectionPage/components/CollectionFooter"
import { useIsLiveUpdatesEnabledForCollection } from "@/features/live-updates/hooks/useIsLiveUpdatesEnabledForCollection"
import {
  LIST_VIEW_ITEM_REMOVAL_DELAY,
  removeItemFromStore,
} from "@/features/live-updates/utils"
import { useItemAddToCart } from "@/features/shopping-cart/components/ItemAddToCartButton"
import { SWEEP_BOTTOM_SHEET_HEIGHT } from "@/features/sweep/constants"
import { useIsLastProbableItemToSweep } from "@/features/sweep/hooks/useIsLastProbableItemToSweep"
import {
  useSweepFormIsSweepModeToggled,
  useSweepUpToItem,
  useSweepItemIneligibilityReasonMap,
} from "@/features/sweep/SweepContextProvider.react"
import { useIsVirtualizedTableViewEnabled } from "@/hooks/useFlag"
import { usePageVisibility } from "@/hooks/usePageVisibility"
import { useScrollIntoViewIfNeeded } from "@/hooks/useScrollIntoViewIfNeeded"
import { CollectionTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/CollectionTrackingContext.react"
import { ItemTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/ItemTrackingContext.react"
import { SourceTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/SourceTrackingContext.react"
import {
  ItemCard_data$data,
  ItemCard_data$key,
} from "@/lib/graphql/__generated__/ItemCard_data.graphql"
import { addressesEqual } from "@/lib/helpers/address"
import { NAV_HEIGHT_PX } from "@/styles/variables"
import {
  AssetSearchListViewSaleOrderColumns,
  Row,
  RowCell,
} from "./AssetSearchListViewOrderColumns"
import {
  COLUMN_MIN_WIDTH,
  COLUMN_PRIMARY_MIN_WIDTH,
  COLUMN_SPACER_WIDTH,
  getAssetSearchViewTableColumnWidth,
} from "./AssetSearchListViewTableHeader"

const TABLE_HEADER_HEIGHT = 70

// NOTE(@laurafiuza): Once VirtualizedTable is merged in collection
// page, we can delete the old Props and old Component
export type NewAssetSearchListViewTableRowProps = {
  showCollectionName?: boolean | undefined
  accountAddress: string | undefined
  assetData: ItemCard_data$key | null
}

export const NewAssetSearchListViewTableRow = ({
  accountAddress,
  showCollectionName,
  assetData,
}: NewAssetSearchListViewTableRowProps) => {
  const itemCardData = useFragment(ItemCardFragment, assetData || null)

  if (!itemCardData) {
    return <NewSkeletonColumns />
  }

  return (
    <ItemCardFooter
      accountAddress={accountAddress}
      item={itemCardData}
      showCollectionName={showCollectionName}
      variant="list-view"
    >
      {AssetSearchListViewTableRowContent}
    </ItemCardFooter>
  )
}

type Props = FeatureTableRenderProps<{
  showQuantity?: boolean | undefined
  showContextMenu?: boolean | undefined
  showCollectionName?: boolean | undefined
  onClick?: (
    item: ItemCard_data$data,
    extra: ItemCardOnClickExtra & { index: number },
  ) => void
  accountAddress?: string | undefined
  fillContainerWidth?: boolean | undefined
  assetData: ItemCard_data$key | null
}>

const _AssetSearchListViewTableRow = ({ data }: Props) => {
  const itemCardData = useFragment(ItemCardFragment, data.assetData || null)

  if (!itemCardData) {
    return <SkeletonColumns />
  }

  return (
    <SourceTrackingContextProvider source="ItemTableRow">
      <ItemTrackingContextProvider item={itemCardData}>
        <CollectionTrackingContextProvider
          collection={
            itemCardData.collection ?? itemCardData.bundleCollection ?? null
          }
        >
          <ItemCardFooter
            accountAddress={data.accountAddress}
            item={itemCardData}
            showCollectionName={data.showCollectionName}
            variant="list-view"
          >
            {AssetSearchListViewTableRowContent}
          </ItemCardFooter>
        </CollectionTrackingContextProvider>
      </ItemTrackingContextProvider>
    </SourceTrackingContextProvider>
  )
}

const AssetSearchListViewTableRowContent: ComponentProps<
  typeof ItemCardFooter
>["children"] = ({
  renderPrice,
  bestAsk,
  isRarityDisplayed,
  rarityContentDataKey,
  item,
  owner,
  title,
}) => {
  const isSweepModeToggled = useSweepFormIsSweepModeToggled()
  const sweepUpToItem = useSweepUpToItem()
  const { itemIneligibilityReasonMap } = useSweepItemIneligibilityReasonMap()
  const disabledReason =
    isSweepModeToggled && item?.relayId
      ? itemIneligibilityReasonMap.get(item.relayId)
      : undefined
  const activeAccount = useActiveAccount()
  const isOwnOrder = addressesEqual(
    bestAsk?.maker.address,
    activeAccount?.address,
  )
  const isMdScreen = useIsLessThanLg()
  const isVirtualizedTableViewEnabled = useIsVirtualizedTableViewEnabled()

  const [containerRef, scrollIntoView] = useScrollIntoViewIfNeeded({
    marginTop: NAV_HEIGHT_PX + FILTER_BAR_HEIGHT + TABLE_HEADER_HEIGHT,
    marginBottom: isMdScreen
      ? SWEEP_BOTTOM_SHEET_HEIGHT
      : COLLECTION_FOOTER_HEIGHT,
  })

  const isLastProbableItemToSweep = useIsLastProbableItemToSweep(item?.relayId)

  useUpdateEffect(() => {
    if (isSweepModeToggled && isLastProbableItemToSweep) {
      scrollIntoView()
    }
  }, [isSweepModeToggled, isLastProbableItemToSweep])

  const { onAddToCart, error } = useItemAddToCart({
    order: bestAsk,
  })

  const isAddToCartAvailable = !isSweepModeToggled && !isOwnOrder && !error

  const onClick = useCallback(() => {
    if (isAddToCartAvailable) {
      onAddToCart()
    } else if (isSweepModeToggled) {
      sweepUpToItem(item?.relayId)
    }
  }, [
    isAddToCartAvailable,
    isSweepModeToggled,
    item?.relayId,
    onAddToCart,
    sweepUpToItem,
  ])

  const isLiveUpdatesEnabledForCollection =
    useIsLiveUpdatesEnabledForCollection(null)
  const animateSaleEvent = Boolean(
    isLiveUpdatesEnabledForCollection &&
      !!item?.saleOrListingCancellationOccurred &&
      item.collection?.slug &&
      item.relayId,
  )

  const environment = useRelayEnvironment()
  const visible = usePageVisibility()
  const timeoutRef = useRef<NodeJS.Timeout | undefined>()
  if (animateSaleEvent && item && item.relayId) {
    if (!timeoutRef.current) {
      const removeHandler = () =>
        commitLocalUpdate(environment, store =>
          removeItemFromStore(item, store),
        )
      if (visible) {
        timeoutRef.current = setTimeout(
          removeHandler,
          LIST_VIEW_ITEM_REMOVAL_DELAY,
        )
      } else {
        removeHandler()
      }
    }
  }

  const isDisabled = !!disabledReason || animateSaleEvent

  return (
    <Flex className="w-full items-center" ref={containerRef}>
      <Tooltip content={disabledReason} disabled={!disabledReason}>
        <Block className="w-full">
          <Flex
            className={classNames(
              "w-full px-4 py-6",
              isVirtualizedTableViewEnabled ? "xl:py-0" : "xl:py-2.5",
              isDisabled && "pointer-events-none opacity-30",
              isAddToCartAvailable ? "cursor-pointer" : "cursor-default",
            )}
            onClick={onClick}
          >
            <AssetSearchListViewSaleOrderColumns
              bestAsk={bestAsk}
              isRarityDisplayed={isRarityDisplayed}
              item={item}
              owner={owner}
              price={renderPrice(
                {
                  size: "small",
                  noTooltip: isOwnOrder,
                },
                true,
                true,
              )}
              rarityContentDataKey={rarityContentDataKey}
              title={title}
            />
          </Flex>
        </Block>
      </Tooltip>
    </Flex>
  )
}

const NewSkeletonColumns = () => {
  const { isCollectionRarityEnabled } = useAccountOrCollectionPageContext()
  const isVirtualizedTableViewEnabled = useIsVirtualizedTableViewEnabled()
  const columnWidth = getAssetSearchViewTableColumnWidth(
    isCollectionRarityEnabled,
    isVirtualizedTableViewEnabled,
  )

  return (
    <Row alignItems="center" minHeight={20} padding="21px 16px 10px 12px">
      <RowCell
        justifyContent="flex-start"
        marginLeft="16px"
        width={COLUMN_SPACER_WIDTH}
      >
        <Checkbox disabled />
      </RowCell>
      <RowCell minWidth={COLUMN_PRIMARY_MIN_WIDTH} width="25%">
        <Flex className="items-center">
          <Skeleton.Block className="mr-5 h-10 w-10 rounded-default" />
          <Skeleton.Line className="h-5 w-[60px]" />
        </Flex>
      </RowCell>
      <RowCell minWidth={COLUMN_PRIMARY_MIN_WIDTH} width={columnWidth}>
        <Skeleton.Line className="h-5 w-[60px]" />
      </RowCell>
      <RowCell minWidth={COLUMN_MIN_WIDTH} width={columnWidth}>
        <Skeleton.Line className="h-5 w-[60px]" />
      </RowCell>
      <RowCell minWidth={COLUMN_MIN_WIDTH} width={columnWidth}>
        <Skeleton.Line className="h-5 w-[60px]" />
      </RowCell>
      {isCollectionRarityEnabled && (
        <RowCell minWidth={COLUMN_MIN_WIDTH} width={columnWidth}>
          <Skeleton.Line className="h-5 w-[60px]" />
        </RowCell>
      )}
      <RowCell minWidth={COLUMN_MIN_WIDTH} width={columnWidth}>
        <Skeleton.Line className="h-5 w-[60px]" />
      </RowCell>
      <RowCell minWidth={COLUMN_MIN_WIDTH} width={columnWidth}>
        <Skeleton.Line className="h-5 w-[60px]" />
      </RowCell>
    </Row>
  )
}

const SkeletonColumns = () => {
  const { isCollectionRarityEnabled } = useAccountOrCollectionPageContext()
  const columnWidth = getAssetSearchViewTableColumnWidth(
    isCollectionRarityEnabled,
    false,
  )

  return (
    <Flex className="w-full items-center px-4 py-2.5">
      <FeatureTable.Cell
        justifyContent="flex-start"
        width={COLUMN_SPACER_WIDTH}
      >
        <Checkbox disabled />
      </FeatureTable.Cell>
      <FeatureTable.Cell
        justifyContent="flex-start"
        minWidth={COLUMN_PRIMARY_MIN_WIDTH}
        width="25%"
      >
        <Flex className="items-center">
          <Skeleton.Block className="mr-5 h-10 w-10 rounded-default" />
          <Skeleton.Line className="h-5 w-[60px]" />
        </Flex>
      </FeatureTable.Cell>
      <FeatureTable.Cell
        justifyContent="flex-start"
        minWidth={COLUMN_PRIMARY_MIN_WIDTH}
        width={columnWidth}
      >
        <Skeleton.Line className="h-5 w-[60px]" />
      </FeatureTable.Cell>
      <FeatureTable.Cell
        justifyContent="flex-start"
        minWidth={COLUMN_MIN_WIDTH}
        width={columnWidth}
      >
        <Skeleton.Line className="h-5 w-[60px]" />
      </FeatureTable.Cell>
      <FeatureTable.Cell
        justifyContent="flex-start"
        minWidth={COLUMN_MIN_WIDTH}
        width={columnWidth}
      >
        <Skeleton.Line className="h-5 w-[60px]" />
      </FeatureTable.Cell>
      {isCollectionRarityEnabled && (
        <FeatureTable.Cell
          justifyContent="flex-start"
          minWidth={COLUMN_MIN_WIDTH}
          width={columnWidth}
        >
          <Skeleton.Line className="h-5 w-[60px]" />
        </FeatureTable.Cell>
      )}
      <FeatureTable.Cell
        justifyContent="flex-start"
        minWidth={COLUMN_MIN_WIDTH}
        width={columnWidth}
      >
        <Skeleton.Line className="h-5 w-[60px]" />
      </FeatureTable.Cell>
      <FeatureTable.Cell
        justifyContent="flex-start"
        minWidth={COLUMN_MIN_WIDTH}
        width={columnWidth}
      >
        <Skeleton.Line className="h-5 w-[60px]" />
      </FeatureTable.Cell>
    </Flex>
  )
}

export const AssetSearchListViewTableRow = memo(_AssetSearchListViewTableRow)
