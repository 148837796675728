/**
 * @generated SignedSource<<96855fadb1d976fa006a006baaac5b52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Price_data$data = {
  readonly decimals: number | null;
  readonly symbol: string | null;
  readonly usdSpotPrice: number | null;
  readonly " $fragmentType": "Price_data";
};
export type Price_data$key = {
  readonly " $data"?: Price_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"Price_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Price_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "decimals",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "symbol",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usdSpotPrice",
      "storageKey": null
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "163fe02e7837633dee3c7a451872e464";

export default node;
