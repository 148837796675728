// Based on react-scroll-sync implementation, but only syncs horizontal scroll.
const onScroll = (
  scrolledElement: HTMLDivElement | null,
  otherElement: HTMLDivElement | null,
) => {
  if (!scrolledElement || !otherElement) {
    return
  }
  // Removes the scroll event handler on the other element to avoid circular
  // events before scrolling.
  window.requestAnimationFrame(() => {
    removeScrollEvent(otherElement)
    otherElement.scrollLeft = scrolledElement.scrollLeft
    window.requestAnimationFrame(() => {
      otherElement.onscroll = () => onScroll(otherElement, scrolledElement)
    })
  })
}

export const addScrollEvent = (
  element: HTMLDivElement | null,
  otherElement: HTMLDivElement | null,
) => {
  if (element && otherElement) {
    element.onscroll = () => onScroll(element, otherElement)
  }
}

export const removeScrollEvent = (element: HTMLDivElement | null) => {
  if (element) {
    element.onscroll = null
  }
}
