import React, { useCallback } from "react"
import { createContext, useContextSelector } from "use-context-selector"

type SearchContextType<T = unknown> = {
  totalCount: number | undefined
  setTotalCount: (value: number | undefined) => void
  lastUpdatedAt: string | undefined
  setLastUpdatedAt: (value: string | undefined) => void
  items: T[] | undefined
  setItems: (value: T[] | undefined) => void
  updateSearchContext: (
    value: Partial<
      Pick<SearchContextType, "totalCount" | "lastUpdatedAt" | "items">
    >,
  ) => void
  clearSearchContext: () => void
}

const SearchContext = createContext<SearchContextType>({
  totalCount: undefined,
  setTotalCount: () => null,
  lastUpdatedAt: undefined,
  setLastUpdatedAt: () => null,
  items: undefined,
  setItems: () => null,
  updateSearchContext: () => null,
  clearSearchContext: () => null,
})

type Props = {
  children: React.ReactNode
}

export const SearchContextProvider = ({ children }: Props) => {
  const [totalCount, setTotalCount] = React.useState<number | undefined>()
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState<string | undefined>()
  const [items, setItems] = React.useState<unknown[] | undefined>()

  const clearSearchContext = useCallback(() => {
    setTotalCount(undefined)
    setLastUpdatedAt(undefined)
    setItems(undefined)
  }, [])

  const updateSearchContext = useCallback(
    (
      searchContext: Partial<
        Pick<SearchContextType, "totalCount" | "lastUpdatedAt" | "items">
      >,
    ) => {
      if ("totalCount" in searchContext) {
        setTotalCount(searchContext.totalCount)
      }
      if ("lastUpdatedAt" in searchContext) {
        setLastUpdatedAt(searchContext.lastUpdatedAt)
      }
      if ("items" in searchContext) {
        setItems(searchContext.items)
      }
    },
    [],
  )

  return (
    <SearchContext.Provider
      value={{
        totalCount,
        setTotalCount,
        lastUpdatedAt,
        setLastUpdatedAt,
        items,
        setItems,
        clearSearchContext,
        updateSearchContext,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export const useSearchContextItems = <T,>() => {
  return [
    useContextSelector(SearchContext, value => value.items as T | undefined),
    useContextSelector(
      SearchContext,
      value => value.setItems as (value: T | undefined) => void,
    ),
  ] as const
}

export const useSearchContextLastUpdatedAt = () => {
  return [
    useContextSelector(SearchContext, value => value.lastUpdatedAt),
    useContextSelector(SearchContext, value => value.setLastUpdatedAt),
  ] as const
}

export const useSearchContextTotalCount = () => {
  return [
    useContextSelector(SearchContext, value => value.totalCount),
    useContextSelector(SearchContext, value => value.setTotalCount),
  ] as const
}

export const useClearSearchContext = () => {
  return useContextSelector(SearchContext, value => value.clearSearchContext)
}

export const useUpdateSearchContext = <T,>() => {
  return useContextSelector(
    SearchContext,
    value =>
      value.updateSearchContext as SearchContextType<T>["updateSearchContext"],
  )
}
