import {
  useGetItemSweepStatus,
  useSweepEligibleItems,
} from "../SweepContextProvider.react"

// Used for scrolling the last probable item to sweep into view
export const useIsLastProbableItemToSweep = (itemRelayId?: string) => {
  const getItemSweepStatus = useGetItemSweepStatus()
  const { eligibleItems } = useSweepEligibleItems()

  const mostProbableItemsToSweep = eligibleItems.filter(
    ({ relayId }) => getItemSweepStatus(relayId).isMostProbableToSweep,
  )

  const itemIndex = mostProbableItemsToSweep.findIndex(
    ({ relayId }) => relayId === itemRelayId,
  )

  return itemIndex >= 0 && itemIndex === mostProbableItemsToSweep.length - 1
}
