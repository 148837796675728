import React, { useEffect, useMemo } from "react"
import { FlexColumn } from "@opensea/ui-kit"
import { Virtualizer } from "@tanstack/react-virtual"
import styled from "styled-components"
import { Block } from "@/design-system/Block"
import { VirtualizedTableProps } from "@/design-system/VirtualizedTable"
import { DEFAULT_PAGINATION_THRESHOLD } from "@/design-system/VirtualizedTable/constants"
import {
  addScrollEvent,
  removeScrollEvent,
} from "@/design-system/VirtualizedTable/utils"
import { useCallbackRef } from "@/hooks/useCallbackRef"

type Props<T> = {
  rowVirtualizer:
    | Virtualizer<Window, Element>
    | Virtualizer<HTMLDivElement, Element>
  setTableRef: (ref: HTMLDivElement) => void
  tableRef: React.RefObject<HTMLDivElement>
} & Omit<VirtualizedTableProps<T>, "itemKey">

export const VirtualizedTableView = <T,>({
  className,
  items,
  itemHeightEstimate,
  hasNext,
  isLoadingNext,
  loadNext,
  paginationThreshold = DEFAULT_PAGINATION_THRESHOLD,
  header,
  height,
  renderRow: Row,
  rowVirtualizer,
  setTableRef,
  tableRef,
}: Props<T>) => {
  const [headerRef, setHeaderRef] = useCallbackRef<HTMLDivElement>()

  // Synchronizes the horizontal scroll position of the header and table. Cannot
  // put the header in the table block itself because the header cannot respect
  // position: sticky when it's virtualized.
  useEffect(() => {
    // There should only be one child element, which is the header component.
    // This is done since we can't pass a ref to the header prop.
    const header = headerRef.current?.firstElementChild as HTMLDivElement | null
    const table = tableRef.current
    if (header && table) {
      addScrollEvent(header, table)
      addScrollEvent(table, header)
    }
    return () => {
      removeScrollEvent(header)
      removeScrollEvent(table)
    }
  }, [headerRef, tableRef])

  const virtualItems = rowVirtualizer.getVirtualItems()
  useEffect(() => {
    if (!hasNext || isLoadingNext || virtualItems.length === 0) {
      return
    }

    const lastVirtualItem = virtualItems[virtualItems.length - 1]
    const lastRealItemIndex = items.length - 1
    const paginationIndex = lastRealItemIndex - paginationThreshold
    if (lastVirtualItem.index >= paginationIndex) {
      loadNext()
    }
  }, [
    hasNext,
    isLoadingNext,
    items.length,
    loadNext,
    paginationThreshold,
    virtualItems,
  ])

  const rowRef = useMemo(() => {
    const isExpandable =
      itemHeightEstimate.max !== undefined &&
      itemHeightEstimate.max > itemHeightEstimate.min

    return isExpandable ? rowVirtualizer.measureElement : undefined
  }, [itemHeightEstimate, rowVirtualizer.measureElement])

  return (
    <FlexColumn
      aria-rowcount={items.length}
      className={className}
      role="table"
      style={{ height }}
    >
      {header && (
        // Display contents to allow header to become sticky with parent.
        <Block
          className="w-full"
          display="contents"
          flex="none"
          ref={setHeaderRef}
          role="rowgroup"
        >
          {header}
        </Block>
      )}
      <Block
        className="w-full"
        flex={1}
        overflow="auto"
        ref={setTableRef}
        role="rowgroup"
      >
        <Block
          className="relative overflow-y-hidden"
          height={rowVirtualizer.getTotalSize()}
        >
          {virtualItems.map(({ index, key, start }) => (
            <RowContainer
              $start={start}
              data-index={index}
              key={key}
              minHeight={itemHeightEstimate.min}
              ref={rowRef}
              role="row"
            >
              <Row index={index} item={items[index]} />
            </RowContainer>
          ))}
        </Block>
      </Block>
    </FlexColumn>
  )
}

type RowContainerProps = { $start: number }

const RowContainer = styled(Block).attrs<RowContainerProps>(props => ({
  // Style specified here rather than directly as a style prop since this value
  // is unique per row. Avoids generating a new CSS className per start value.
  style: {
    transform: `translateY(${props.$start}px)`,
  },
}))<RowContainerProps>`
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`
