import React, { forwardRef } from "react"
import { Text } from "@opensea/ui-kit"
import {
  VirtualizedTableCell,
  VirtualizedTableCellProps,
} from "@/design-system/VirtualizedTable/components/VirtualizedTableCell.react"

type Props = VirtualizedTableCellProps

export const VirtualizedTableHeaderCell = forwardRef<HTMLDivElement, Props>(
  function VirtualizedTableHeaderCell({ children, ...cellProps }: Props, ref) {
    return (
      <VirtualizedTableCell
        backgroundColor="components.background.transparent"
        ref={ref}
        role="columnheader"
        {...cellProps}
      >
        <Text.Body className="text-secondary" size="small">
          {children}
        </Text.Body>
      </VirtualizedTableCell>
    )
  },
)
