import React, { ComponentProps, ReactNode } from "react"
import { Text, Checkbox } from "@opensea/ui-kit"
import styled from "styled-components"
import { AccountLink } from "@/components/accounts/AccountLink.react"
import { AssetQuantity } from "@/components/assets/AssetQuantity.react"
import { useItemMetadata } from "@/components/assets/ItemMetadata.react"
import { LiveTimestamp } from "@/components/common/LiveTimestamp.react"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { AssetSearchListViewTableQuickBuy } from "@/components/search/assets/AssetSearchListViewTable/AssetSearchListViewTableQuickBuy"
import { Block } from "@/design-system/Block"
import { FeatureTable } from "@/design-system/FeatureTable"
import { VirtualizedTable } from "@/design-system/VirtualizedTable"
import { useIsVirtualizedTableViewEnabled } from "@/hooks/useFlag"
import { AccountLink_data$key } from "@/lib/graphql/__generated__/AccountLink_data.graphql"
import { AssetSearchListViewTableQuickBuy_order$key } from "@/lib/graphql/__generated__/AssetSearchListViewTableQuickBuy_order.graphql"
import { ItemAddToCartButton_order$key } from "@/lib/graphql/__generated__/ItemAddToCartButton_order.graphql"
import { ItemCardFooter$data } from "@/lib/graphql/__generated__/ItemCardFooter.graphql"
import { RarityIndicator_data$key } from "@/lib/graphql/__generated__/RarityIndicator_data.graphql"
import { useIsQuickBuyEnabled_order$key } from "@/lib/graphql/__generated__/useIsQuickBuyEnabled_order.graphql"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { display } from "@/lib/helpers/numberUtils"
import { EMPTY_PRICE_DISPLAY } from "../../../../constants"
import { AssetSearchListViewTableAddToCart } from "./AssetSearchListViewTableAddToCart"
import { AssetSearchListViewTableAssetInfo } from "./AssetSearchListViewTableAssetInfo"
import {
  COLUMN_MIN_WIDTH,
  COLUMN_PRIMARY_MIN_WIDTH,
  COLUMN_SPACER_MIN_WIDTH,
  COLUMN_SPACER_WIDTH,
  getAssetSearchViewTableColumnWidth,
} from "./AssetSearchListViewTableHeader"
import { AssetSearchListViewTableRarityInfo } from "./AssetSearchListViewTableRarityInfo"

type BestAsk = ItemAddToCartButton_order$key &
  AssetSearchListViewTableQuickBuy_order$key &
  useIsQuickBuyEnabled_order$key

type Props = {
  item: ItemCardFooter$data | null
  isRarityDisplayed: boolean
  owner?: {
    owner: AccountLink_data$key
  } | null
  rarityContentDataKey: RarityIndicator_data$key | null
  price: ReactNode
  bestAsk?: BestAsk | null
} & Partial<ComponentProps<typeof AssetSearchListViewTableAssetInfo>>

const Empty = () => {
  return <span>{EMPTY_PRICE_DISPLAY}</span>
}

export const AssetSearchListViewSaleOrderColumns = React.memo(
  function AssetSearchListViewSaleOrderColumns({
    bestAsk,
    item,
    isRarityDisplayed,
    owner,
    rarityContentDataKey,
    price,
    title,
  }: Props) {
    const {
      bestAsk: itemBestAsk,
      bestBid,
      lastSale,
    } = useItemMetadata({ item })
    const { isCollectionRarityEnabled } = useAccountOrCollectionPageContext()
    const isVirtualizedTableViewEnabled = useIsVirtualizedTableViewEnabled()
    const columnWidth = getAssetSearchViewTableColumnWidth(
      isCollectionRarityEnabled,
      isVirtualizedTableViewEnabled,
    )

    if (isVirtualizedTableViewEnabled) {
      return (
        <Row alignItems="center" minHeight={20} padding="21px 16px 10px 12px">
          <RowCell
            flexShrink={1}
            justifyContent="flex-start"
            minWidth={COLUMN_SPACER_MIN_WIDTH}
            width={COLUMN_SPACER_WIDTH}
          >
            {price && bestAsk ? (
              <AssetSearchListViewTableAddToCart
                itemRelayId={item?.relayId}
                order={bestAsk}
              />
            ) : (
              <Checkbox disabled />
            )}
          </RowCell>
          <RowCell
            justifyContent="flex-start"
            minWidth={COLUMN_PRIMARY_MIN_WIDTH}
            width="25%"
          >
            {!!item && (
              <AssetSearchListViewTableAssetInfo
                item={item}
                order={bestAsk}
                title={title}
              />
            )}
          </RowCell>
          <RowCell
            justifyContent="flex-start"
            minWidth={COLUMN_PRIMARY_MIN_WIDTH}
            width={columnWidth}
          >
            <AssetSearchListViewTableQuickBuy
              order={bestAsk ?? null}
              price={price}
            />
          </RowCell>
          <RowCell
            justifyContent="flex-start"
            minWidth={COLUMN_MIN_WIDTH}
            width={columnWidth}
          >
            <Text.Body asChild className="max-w-full" size="small">
              <div>
                {bestBid ? (
                  <>
                    {display(bestBid.perUnitPriceType.unit)}{" "}
                    {bestBid.perUnitPriceType.symbol}
                  </>
                ) : (
                  <Empty />
                )}
              </div>
            </Text.Body>
          </RowCell>
          <RowCell
            justifyContent="flex-start"
            minWidth={COLUMN_MIN_WIDTH}
            width={columnWidth}
          >
            <Text.Body asChild className="max-w-full" size="small">
              <div>
                {lastSale && lastSale.unitPriceQuantity ? (
                  <AssetQuantity
                    data={lastSale.unitPriceQuantity}
                    fontWeight={400}
                    maxDecimalPlaces={3}
                    noTooltip
                  />
                ) : (
                  <Empty />
                )}
              </div>
            </Text.Body>
          </RowCell>
          {isCollectionRarityEnabled && (
            <RowCell
              justifyContent="flex-start"
              minWidth={COLUMN_MIN_WIDTH}
              width={columnWidth}
            >
              <Text.Body size="small">
                {isRarityDisplayed ? (
                  <AssetSearchListViewTableRarityInfo
                    dataKey={rarityContentDataKey}
                  />
                ) : (
                  <Empty />
                )}
              </Text.Body>
            </RowCell>
          )}
          <RowCell
            justifyContent="flex-start"
            minWidth={COLUMN_MIN_WIDTH}
            width={columnWidth}
          >
            <Text.Body size="small">
              {owner ? (
                <Block maxWidth="100px">
                  <Overflow>
                    <AccountLink dataKey={owner.owner} variant="no-image" />
                  </Overflow>
                </Block>
              ) : (
                <Empty />
              )}
            </Text.Body>
          </RowCell>
          <RowCell
            justifyContent="flex-start"
            minWidth={COLUMN_MIN_WIDTH}
            width={columnWidth}
          >
            <Text.Body size="small">
              {itemBestAsk?.openedAt ? (
                <LiveTimestamp
                  fromDate={dateFromISO8601(itemBestAsk.createdDate)}
                />
              ) : (
                <Empty />
              )}
            </Text.Body>
          </RowCell>
        </Row>
      )
    }

    return (
      <>
        <FeatureTable.Cell
          flexShrink={1}
          justifyContent="flex-start"
          minWidth={COLUMN_SPACER_MIN_WIDTH}
          width={COLUMN_SPACER_WIDTH}
        >
          {price && bestAsk ? (
            <AssetSearchListViewTableAddToCart
              itemRelayId={item?.relayId}
              order={bestAsk}
            />
          ) : (
            <Checkbox disabled />
          )}
        </FeatureTable.Cell>
        <FeatureTable.Cell
          justifyContent="flex-start"
          minWidth={COLUMN_PRIMARY_MIN_WIDTH}
          width="25%"
        >
          {!!item && (
            <AssetSearchListViewTableAssetInfo
              item={item}
              order={bestAsk}
              title={title}
            />
          )}
        </FeatureTable.Cell>
        <FeatureTable.Cell
          alignItems="center"
          justifyContent="flex-start"
          minWidth={COLUMN_PRIMARY_MIN_WIDTH}
          width={columnWidth}
        >
          <AssetSearchListViewTableQuickBuy
            order={bestAsk ?? null}
            price={price}
          />
        </FeatureTable.Cell>
        <FeatureTable.Cell
          justifyContent="flex-start"
          minWidth={COLUMN_MIN_WIDTH}
          width={columnWidth}
        >
          <Text.Body asChild className="max-w-full" size="small">
            <div>
              {bestBid ? (
                <>
                  {display(bestBid.perUnitPriceType.unit)}{" "}
                  {bestBid.perUnitPriceType.symbol}
                </>
              ) : (
                <Empty />
              )}
            </div>
          </Text.Body>
        </FeatureTable.Cell>
        <FeatureTable.Cell
          justifyContent="flex-start"
          minWidth={COLUMN_MIN_WIDTH}
          width={columnWidth}
        >
          <Text.Body asChild className="max-w-full" size="small">
            <div>
              {lastSale && lastSale.unitPriceQuantity ? (
                <AssetQuantity
                  data={lastSale.unitPriceQuantity}
                  fontWeight={400}
                  maxDecimalPlaces={3}
                  noTooltip
                />
              ) : (
                <Empty />
              )}
            </div>
          </Text.Body>
        </FeatureTable.Cell>
        {isCollectionRarityEnabled && (
          <FeatureTable.Cell
            justifyContent="flex-start"
            minWidth={COLUMN_MIN_WIDTH}
            width={columnWidth}
          >
            <Text.Body size="small">
              {isRarityDisplayed ? (
                <AssetSearchListViewTableRarityInfo
                  dataKey={rarityContentDataKey}
                />
              ) : (
                <Empty />
              )}
            </Text.Body>
          </FeatureTable.Cell>
        )}
        <FeatureTable.Cell
          justifyContent="flex-start"
          minWidth={COLUMN_MIN_WIDTH}
          width={columnWidth}
        >
          <Text.Body size="small">
            {owner ? (
              <Block maxWidth="100px">
                <Overflow>
                  <AccountLink dataKey={owner.owner} variant="no-image" />
                </Overflow>
              </Block>
            ) : (
              <Empty />
            )}
          </Text.Body>
        </FeatureTable.Cell>
        <FeatureTable.Cell
          justifyContent="flex-start"
          minWidth={COLUMN_MIN_WIDTH}
          width={columnWidth}
        >
          <Text.Body size="small">
            {itemBestAsk?.openedAt ? (
              <LiveTimestamp
                fromDate={dateFromISO8601(itemBestAsk.createdDate)}
              />
            ) : (
              <Empty />
            )}
          </Text.Body>
        </FeatureTable.Cell>
      </>
    )
  },
)

const Overflow = styled(Block)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`

export const RowCell = styled(VirtualizedTable.Row.Cell)``

export const Row = styled(VirtualizedTable.Row)`
  align-items: center;
  border-bottom: 1px solid
    ${props => props.theme.colors.components.border.level2};
  width: 100%;

  &:hover {
    background: ${props => props.theme.colors.components.background.gray1};
  }

  &:hover ${RowCell} {
    background: ${props => props.theme.colors.components.background.gray1};
  }
`
