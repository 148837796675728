import React, { forwardRef } from "react"
import styled, { css, CSSProperties } from "styled-components"
import { Flex, FlexProps } from "@/design-system/Flex"
import { DEFAULT_CELL_WIDTH_PX } from "@/design-system/VirtualizedTable/constants"

export type VirtualizedTableCellProps = {
  children: React.ReactNode
  className?: string
  role?: "cell" | "columnheader"
  /** Set to a number to make the cell horizontally sticky. */
  stickyLeft?: number
  backgroundOverride?: string
} & FlexProps

export const VirtualizedTableCell = forwardRef<
  HTMLDivElement,
  VirtualizedTableCellProps
>(function VirtualizedTableCell(
  {
    alignItems = "center",
    children,
    className,
    backgroundOverride,
    flexShrink = 0,
    flexGrow = 0,
    stickyLeft,
    minWidth,
    role = "cell",
    width = DEFAULT_CELL_WIDTH_PX,
    ...flexProps
  }: VirtualizedTableCellProps,
  ref,
) {
  return (
    <Container
      $background={backgroundOverride}
      $stickyLeft={stickyLeft}
      alignItems={alignItems}
      className={className}
      flexGrow={flexGrow}
      flexShrink={flexShrink}
      minWidth={minWidth}
      ref={ref}
      role={role}
      width={width}
      {...flexProps}
    >
      {children}
    </Container>
  )
})

const Container = styled(Flex)<{
  $stickyLeft?: number
  $background: CSSProperties["background"] | null | undefined
}>`
  background: ${props => props.$background || props.theme.colors.base1};

  ${props =>
    props.$stickyLeft !== undefined
      ? css`
          height: 100%; // Ensure border spans height of row.
          left: ${props.$stickyLeft}px;
          position: sticky;
          z-index: 1;
        `
      : ""}
`
