import React from "react"
import { UnstyledButton, Text, Spinner, Flex } from "@opensea/ui-kit"
import NextLink from "next/link"
import { rgba } from "polished"
import { useFragment } from "react-relay"
import styled, { css } from "styled-components"
import { ItemCardContent } from "@/components/assets/ItemCardContent.react"
import {
  SHOULD_NOT_ANIMATE_TRANSACTION_STATUS,
  useTransactionStatusText,
} from "@/components/assets/ItemCardFooter.react"
import { ItemCardPendingTxnTooltip } from "@/components/assets/ItemCardPendingTxnTooltip.react"
import { Block } from "@/design-system/Block"
import { Checkmark } from "@/design-system/Checkmark"
import { Tooltip } from "@/design-system/Tooltip"
import { PortfolioTableItemCellTooltip } from "@/features/account/components/PortfolioTable/components/tooltips/PortfolioTableItemCellTooltip.react"
import { useIsLiveUpdatesEnabledForCollection } from "@/features/live-updates/hooks/useIsLiveUpdatesEnabledForCollection"
import { useItemAddToCart } from "@/features/shopping-cart/components/ItemAddToCartButton"
import {
  useSweepFormIsSweepModeToggled,
  useGetItemSweepStatus,
} from "@/features/sweep/SweepContextProvider.react"
import { AssetSearchListViewTableAssetInfo_item$key } from "@/lib/graphql/__generated__/AssetSearchListViewTableAssetInfo_item.graphql"
import { ItemAddToCartButton_order$key } from "@/lib/graphql/__generated__/ItemAddToCartButton_order.graphql"
import { ItemCardFooter$data } from "@/lib/graphql/__generated__/ItemCardFooter.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { getItemUrl } from "@/lib/helpers/item"

type Props = {
  item: ItemCardFooter$data | null
  order?: ItemAddToCartButton_order$key | null
  title?: string
}

export const AssetSearchListViewTableAssetInfo = ({
  item: itemKey,
  order,
  title,
}: Props) => {
  const item = useFragment<AssetSearchListViewTableAssetInfo_item$key>(
    graphql`
      fragment AssetSearchListViewTableAssetInfo_item on ItemType {
        __typename
        pendingTxn {
          status
          maxFeeGwei
          priorityFeeGwei
          seenAt
          transactionHash
          blockExplorerLink
        }
        saleOrListingCancellationOccurred
        ...PortfolioTableItemCellTooltip_item
      }
    `,
    itemKey?.__typename === "AssetType" ? itemKey : null,
  )

  // Accessing context in leaf node of table row to avoid re-rendering entire
  // row component when context values change
  const isSweepModeToggled = useSweepFormIsSweepModeToggled()
  const getItemSweepStatus = useGetItemSweepStatus()
  const itemSweepStatus = getItemSweepStatus(itemKey?.relayId)
  const isSelectedForSweep =
    !!isSweepModeToggled && itemSweepStatus.isMostProbableToSweep

  const { isOrderAlreadyAdded } = useItemAddToCart({
    order,
  })
  const isSelectedForCart = !isSweepModeToggled && isOrderAlreadyAdded

  const isLiveUpdatesEnabledForCollection =
    useIsLiveUpdatesEnabledForCollection(null)

  const animateSaleEvent = Boolean(
    isLiveUpdatesEnabledForCollection &&
      !!item?.saleOrListingCancellationOccurred,
  )
  const animatePendingTxn = Boolean(
    isLiveUpdatesEnabledForCollection &&
      item?.pendingTxn &&
      item.pendingTxn.status &&
      !SHOULD_NOT_ANIMATE_TRANSACTION_STATUS.includes(item.pendingTxn.status),
  )

  const renderItemImage = () => (
    <StyledItemCardContent
      $isSelected={isSelectedForCart || isSelectedForSweep}
      fillContainerWidth={false}
      isListView
      item={itemKey}
      natural={false}
      width={40}
    />
  )

  const renderItemName = () => (
    <Text size="small" weight="semibold">
      <Overflow>
        {itemKey ? (
          <NextLink href={getItemUrl(itemKey)} legacyBehavior passHref>
            <StyledLink
              data-testid="ItemCardFooter-name"
              onClick={e => {
                e.stopPropagation()
              }}
            >
              {title}
            </StyledLink>
          </NextLink>
        ) : (
          title
        )}
      </Overflow>
    </Text>
  )

  const {
    maxFeeGwei,
    priorityFeeGwei,
    seenAt,
    transactionHash,
    blockExplorerLink,
  } = item?.pendingTxn ?? {}

  const { completeText, pendingText } = useTransactionStatusText()

  return (
    <Flex className="max-w-full items-center">
      <Block className="relative">
        <PortfolioTableItemCellTooltip item={item} placement="bottom-start">
          {renderItemImage()}
        </PortfolioTableItemCellTooltip>
      </Block>

      <NameAndLiveIcon>
        <PortfolioTableItemCellTooltip
          item={item}
          offset={[-16, 24]}
          placement="bottom-start"
        >
          {renderItemName()}
        </PortfolioTableItemCellTooltip>
        {animateSaleEvent ? (
          <Tooltip content={completeText}>
            <Checkmark />
          </Tooltip>
        ) : animatePendingTxn ? (
          <UnstyledButton
            aria-label={pendingText}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              item?.pendingTxn?.blockExplorerLink &&
                window.open(item.pendingTxn.blockExplorerLink, "_blank")
            }}
          >
            <Tooltip
              content={
                <ItemCardPendingTxnTooltip
                  blockExplorerLink={blockExplorerLink}
                  maxFeeGwei={maxFeeGwei}
                  priorityFeeGwei={priorityFeeGwei}
                  seenAt={seenAt ? dateFromISO8601(seenAt) : null}
                  transactionHash={transactionHash}
                />
              }
              interactive
              offset={[0, 12]}
            >
              <Block>
                <Spinner />
              </Block>
            </Tooltip>
          </UnstyledButton>
        ) : (
          <div style={{ width: "20px" }}></div>
        )}
      </NameAndLiveIcon>
    </Flex>
  )
}

const NameAndLiveIcon = styled(Flex)`
  gap: 8px;
  margin-left: 20px;
  max-width: calc(100% - 80px);
  padding-right: 20px;
  // TODO(guscost-opensea): Refactor asset display to remove nested selectors.
  // We need this because the 100% width has to propagate through to properly
  // size the names in the table view, and show their live-update icons.
  & > * {
    width: 100%;
  }
`

const Overflow = styled(Block)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`

const StyledLink = styled.a`
  color: ${props => props.theme.colors.text.primary};
  text-decoration: none;

  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    color: ${props => rgba(props.theme.colors.text.primary, 0.8)};
  }
`

const StyledItemCardContent = styled(ItemCardContent)<{ $isSelected: boolean }>`
  border-radius: ${props => props.theme.borderRadius.default};
  transition: box-shadow 0.3s cubic-bezier(0.05, 0, 0.2, 1);
  box-shadow: 0 0 0 2px transparent;
  flex-shrink: 0;

  ${props =>
    props.$isSelected &&
    css`
      box-shadow: 0 0 0 2px ${props => props.theme.colors.primary};
    `}
`
