import React, { memo, useEffect, useMemo, useRef } from "react"
import { breakpoints } from "@opensea/ui-kit"
import { useInView } from "react-intersection-observer"
import { useRafState } from "react-use"
import styled, { css } from "styled-components"
import type { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { generateSizesFromBreakpointColumnsConfig } from "@/design-system/Image/utils"
import { getLoadMoreFromPagination } from "@/design-system/utils/pagination"
import { useMountEffect } from "@/hooks/useMountEffect"
import { PageProps } from "@/lib/graphql/graphql"

type Props<T> = {
  items: T[]
  getKey: (item: T, index: number) => React.Key
  pagination?: {
    isFirstPageLoading?: boolean
    disableLoader?: boolean
    page: PageProps
    size: number
    children?: React.ReactNode
    onLoad?: () => unknown
    onLoadStart?: () => unknown
  }
  isLoading?: boolean
  renderItem: React.ComponentType<{
    index: number
    data: T
    width: number
    containerWidth?: number
    fillContainerWidth?: boolean
    sizes?: string
  }>
  showAssetMediaEditions?: boolean
  showQuantityBadge?: boolean
  variant: AssetCardVariant
  isItemLoaded: (index: number, items: T[]) => boolean
  /**
   * This changes the # of grid columns the items take up, 1 column on cozy, 2 column on compact
   */
  isReducedColumns?: boolean
}

const getDesktopImageSizes = (variant: AssetCardVariant) => {
  // All the config here is based on the Container styles
  if (variant === "compact") {
    const sizes: [string, number][] = [
      ["80rem", 5],
      ["90rem", 6],
      ["100rem", 7],
      ["120rem", 8],
      ["130rem", 9],
      ["140rem", 10],
      ["150rem", 11],
    ]
    return generateSizesFromBreakpointColumnsConfig({
      sizes,
      defaultColumns: 12,
    })
  }

  const sizes: [string, number][] = [
    ["70rem", 3],
    ["90rem", 4],
    ["110rem", 5],
    ["130rem", 6],
    ["150rem", 7],
  ]
  return generateSizesFromBreakpointColumnsConfig({
    sizes,
    defaultColumns: 8,
  })
}

export const getAssetItemsGridImageSizes = (variant: AssetCardVariant) => {
  // This is the sizes string for the image tag, it's a bit complicated because we want to use a different number of columns depending on the viewport width
  // The code here is tightly coupled to the css grid styles in Container element in this file, and when that is updated this will need to be updated as well
  // It accounts for the fact that the number of columns changes at different breakpoints, but does not currently factor in the gutter size
  // The sizes string is a comma separated list of media queries and sizes, the browser will pick the first media query that matches and use the corresponding size
  const mobileSizes = `(max-width: 20rem) 100vw, (max-width: 30rem) 50vw, (max-width: 50rem) 33.33vw, (max-width: 60rem) 25vw, (max-width: ${breakpoints.lg}px) 20vw,`

  const desktopSizes = getDesktopImageSizes(variant)
  return `${mobileSizes} ${desktopSizes}`
}

/**
 * This items grid is used for rendering asset items
 */
const _AssetItemsGrid = <T,>({
  items,
  getKey,
  renderItem: Item,
  pagination,
  variant,
  isItemLoaded,
  isReducedColumns,
  showAssetMediaEditions,
  showQuantityBadge,
}: Props<T>) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  // Find first index of unloaded
  const firstUnloadedItemIndex = useMemo(
    () => items.findIndex((_, idx) => !isItemLoaded(idx, items)),
    [items, isItemLoaded],
  )

  const [topOffset, setTopOffset] = useRafState(150)

  const {
    ref: unloadedItemWrapperRef,
    inView,
    entry,
  } = useInView({
    initialInView: false,
    // This adds in some margin where the actual intersection observer would start triggering
    rootMargin: `-${topOffset}px 0px ${topOffset}px 0px`,
  })

  useMountEffect(() => {
    setTopOffset(Math.max(window.innerHeight / 2, 150))
  })

  useEffect(() => {
    // disable initial fetch on page, only meant for when user is scrolling down
    // checking entry bounding top < 0 ensures if it's above fold of screen already we still trigger
    if (inView || (entry && entry.boundingClientRect.top < 0)) {
      getLoadMoreFromPagination(pagination)()
    }
  }, [inView, pagination, entry])

  const sizes = useMemo(() => getAssetItemsGridImageSizes(variant), [variant])

  return (
    <Container
      $isReducedColumns={isReducedColumns}
      $variant={variant}
      ref={containerRef}
    >
      {items.map((item, idx) => {
        const itemProps = {
          containerWidth: 0,
          data: item,
          fillContainerWidth: true,
          index: idx,
          width: 380,
          showQuantityBadge,
          showAssetMediaEditions,
          sizes,
        }

        if (firstUnloadedItemIndex === idx) {
          return (
            <span key={getKey(item, idx)} ref={unloadedItemWrapperRef}>
              <Item {...itemProps} />
            </span>
          )
        }

        return <Item key={getKey(item, idx)} {...itemProps} />
      })}
    </Container>
  )
}

const Container = styled.div<{
  $variant: AssetCardVariant
  $isReducedColumns?: boolean
}>`
  --template-column-gutters: ${props => props.theme.spacing.unit}px;
  --template-columns: 1;
  --template-column-compact-multiplier: 1;
  --template-reduced-columns: 0;
  --template-reduced-columns-multiplier: ${props =>
    props.$variant === "compact" ? 2 : 1};

  display: grid;
  gap: var(--template-column-gutters);

  grid-auto-rows: minmax(0, 1fr);
  grid-template-columns: repeat(
    calc(
      var(--template-columns) -
        (
          var(--template-reduced-columns) *
            var(--template-reduced-columns-multiplier)
        )
    ),
    minmax(0, 1fr)
  );

  @media (min-width: 20rem) {
    --template-columns: 2;
  }

  @media (min-width: 30rem) {
    --template-columns: 3;
  }

  @media (min-width: 50rem) {
    --template-columns: 4;
  }

  @media (min-width: 60rem) {
    --template-columns: 5;
  }

  @media (min-width: ${breakpoints.md}px) {
    --template-column-gutters: ${props => 2 * props.theme.spacing.unit}px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    --template-reduced-columns: ${props => (props.$isReducedColumns ? 1 : 0)};
  }

  @media (min-width: ${breakpoints.xxxl}px) {
    --template-column-gutters: ${props => 3 * props.theme.spacing.unit}px;
  }

  ${props =>
    props.$variant === "compact"
      ? css`
          @media (min-width: ${breakpoints.lg}px) {
            --template-columns: 5;
          }

          @media (min-width: 80rem) {
            --template-columns: 6;
          }

          @media (min-width: 90rem) {
            --template-columns: 7;
          }

          @media (min-width: 100rem) {
            --template-columns: 8;
          }

          @media (min-width: 120rem) {
            --template-columns: 9;
          }

          @media (min-width: 130rem) {
            --template-columns: 10;
          }

          @media (min-width: 140rem) {
            --template-columns: 11;
          }

          @media (min-width: 150rem) {
            --template-columns: 12;
          }
        `
      : css`
          @media (min-width: ${breakpoints.lg}px) {
            --template-columns: 3;
          }

          @media (min-width: 70rem) {
            --template-columns: 4;
          }

          @media (min-width: 90rem) {
            --template-columns: 5;
          }

          @media (min-width: 110rem) {
            --template-columns: 6;
          }

          @media (min-width: 130rem) {
            --template-columns: 7;
          }

          @media (min-width: 150rem) {
            --template-columns: 8;
          }
        `}
`

export const AssetItemsGrid = memo(_AssetItemsGrid) as typeof _AssetItemsGrid
