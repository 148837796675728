import React, { CSSProperties, useCallback } from "react"
import {
  observeWindowOffset,
  useVirtualizer,
  useWindowVirtualizer,
  windowScroll,
} from "@tanstack/react-virtual"
import { Virtualizer, ScrollToOptions } from "@tanstack/virtual-core"
import {
  VirtualizedTableHeader,
  VirtualizedTableHeaderProps,
  VirtualizedTableRow,
  VirtualizedTableView,
} from "@/design-system/VirtualizedTable/components"
import {
  DEFAULT_ITEM_HEIGHT_PX,
  DEFAULT_OVERSCAN_BY,
} from "@/design-system/VirtualizedTable/constants"
import { useCallbackRef } from "@/hooks/useCallbackRef"

export type TableRowRenderProps<T> = {
  index: number
  item: T
}

type VirtualizerProps<T> = {
  /** Unique key for each item. */
  itemKey: (item: T | undefined) => string | undefined
  /**
   * Estimated height of an item row in pixels. Providing accurate heights is
   * important for scrolling and row-rendering performance. If the row expands
   * vertically, provide a max value which is the height of the expanded state.
   * This is the sum of the original row plus the height of the expanded portion
   * which usually appears below the original row.
   */
  itemHeightEstimate: { min: number; max?: number }
  overscanBy?: number
}

export type VirtualizedTablePaginationProps = {
  hasNext: boolean
  isLoadingNext: boolean
  loadNext: () => unknown
  /**
   * The threshold at which to pre-fetch data. A threshold X means that new data
   * should start loading when a user scrolls within X cells of the end of the
   * `items` array.
   *
   * @defaultValue `DEFAULT_PAGINATION_THRESHOLD`
   */
  paginationThreshold?: number
}

export type VirtualizedTableProps<T> = {
  className?: string
  items: T[]
  height?: CSSProperties["height"]
  header?: React.ReactElement<VirtualizedTableHeaderProps>
  renderRow: React.ComponentType<TableRowRenderProps<T>>
  /**
   * Determines whether to use window or direct table scrolling. In `window`
   * mode, scrolling the browser window will cause the table to scroll even when
   * the user is not hovering over the table html element directly. In `table`
   * mode, only scrolling on top of the table will cause the table to scroll.
   *
   * When using `table` mode, it is very important that you pass in a `height`.
   * Otherwise, the table will not virtualize correctly, leading to performance
   * issues. TODO(janclarin): enforce passing in a height with types?
   *
   * @defaultValue `"window"`
   */
  scrollMode?: "window" | "table"
} & VirtualizerProps<T> &
  VirtualizedTablePaginationProps

const VirtualizedTableBase = <T,>({
  itemHeightEstimate = { min: DEFAULT_ITEM_HEIGHT_PX },
  overscanBy = DEFAULT_OVERSCAN_BY,
  scrollMode = "window",
  ...props
}: VirtualizedTableProps<T>) => {
  if (scrollMode === "table") {
    return (
      <TableScrollVirtualizedTable
        itemHeightEstimate={itemHeightEstimate}
        overscanBy={overscanBy}
        scrollMode={scrollMode}
        {...props}
      />
    )
  }
  return (
    <WindowScrollVirtualizedTable
      itemHeightEstimate={itemHeightEstimate}
      overscanBy={overscanBy}
      scrollMode={scrollMode}
      {...props}
    />
  )
}

const WindowScrollVirtualizedTable = <T,>({
  items,
  itemKey,
  itemHeightEstimate,
  overscanBy,
  ...props
}: VirtualizedTableProps<T>) => {
  const [tableRef, setTableRef] = useCallbackRef<HTMLDivElement>()
  const getItemKey = useCallback(
    (index: number) => itemKey(items[index]) ?? index,
    [itemKey, items],
  )
  // Adjusts when the virtualizer thinks the table is being scrolled to account
  // for content above the table. This avoids the hack of needing to increase
  // overscanBy to account for the content, which affects scroll performance.
  const observeElementOffset = useCallback(
    (instance: Virtualizer<Window, Element>, cb: (offset: number) => void) => {
      const tableTopOffset = tableRef.current?.offsetTop ?? 0
      return observeWindowOffset(instance, offset =>
        cb(offset - tableTopOffset),
      )
    },
    [tableRef],
  )
  const scrollToFn = useCallback(
    (
      offset: number,
      options: {
        adjustments?: number
        behavior?: ScrollToOptions["behavior"]
      },
      instance: Virtualizer<Window, Element>,
    ) => {
      const tableTopOffset = tableRef.current?.offsetTop ?? 0
      windowScroll(
        offset,
        {
          adjustments: Math.min(window.scrollY, tableTopOffset),
          behavior: options.behavior,
        },
        instance,
      )
    },
    [tableRef],
  )
  const rowVirtualizer = useWindowVirtualizer({
    count: items.length,
    estimateSize: () => itemHeightEstimate.max ?? itemHeightEstimate.min,
    getItemKey,
    overscan: overscanBy,
    observeElementOffset,
    scrollToFn,
  })
  return (
    <VirtualizedTableView
      itemHeightEstimate={itemHeightEstimate}
      items={items}
      rowVirtualizer={rowVirtualizer}
      setTableRef={setTableRef}
      tableRef={tableRef}
      {...props}
    />
  )
}

const TableScrollVirtualizedTable = <T,>({
  items,
  itemKey,
  itemHeightEstimate,
  overscanBy,
  ...props
}: VirtualizedTableProps<T>) => {
  const [tableRef, setTableRef] = useCallbackRef<HTMLDivElement>()
  const getItemKey = useCallback(
    (index: number) => itemKey(items[index]) ?? index,
    [itemKey, items],
  )
  const rowVirtualizer = useVirtualizer({
    count: items.length,
    estimateSize: () => itemHeightEstimate.max ?? itemHeightEstimate.min,
    getItemKey,
    getScrollElement: () => tableRef.current,
    overscan: overscanBy,
  })
  return (
    <VirtualizedTableView
      itemHeightEstimate={itemHeightEstimate}
      items={items}
      rowVirtualizer={rowVirtualizer}
      setTableRef={setTableRef}
      tableRef={tableRef}
      {...props}
    />
  )
}

export const VirtualizedTable = Object.assign(VirtualizedTableBase, {
  Header: VirtualizedTableHeader,
  Row: VirtualizedTableRow,
})
