import React, { memo, ReactNode } from "react"
import { classNames, Flex, Icon } from "@opensea/ui-kit"
import { isAfter } from "date-fns"
import { graphql, useFragment } from "react-relay"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { NetworkUnsupportedGate } from "@/components/modals/NetworkUnsupportedGate"
import { useIsQuickBuyEnabled } from "@/components/trade/QuickBuyButton"
import { IS_SERVER } from "@/constants/environment"
import { useActiveAccount } from "@/containers/WalletProvider/WalletProvider.react"
import { Tooltip } from "@/design-system/Tooltip"
import { useScheduledOrderText } from "@/hooks/useScheduledOrderText"
import { useTranslate } from "@/hooks/useTranslate"
import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"
import { AssetSearchListViewTableQuickBuy_order$key } from "@/lib/graphql/__generated__/AssetSearchListViewTableQuickBuy_order.graphql"
import { useIsQuickBuyEnabled_order$key } from "@/lib/graphql/__generated__/useIsQuickBuyEnabled_order.graphql"
import { addressesEqual } from "@/lib/helpers/address"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { EMPTY_PRICE_DISPLAY } from "../../../../constants"

type AssetSearchListTableQuickBuyButtonProps = {
  order: AssetSearchListViewTableQuickBuy_order$key
  children: React.ReactNode
}

const AssetSearchListTableQuickBuyButton = React.forwardRef<
  HTMLDivElement,
  AssetSearchListTableQuickBuyButtonProps
>(function AssetSearchListTableQuickBuyButton(
  { order: orderDataKey, children },
  ref,
) {
  const t = useTranslate("orders")
  const trackClickQuickBuy = useTrackingFn("click quick buy")

  const activeAccount = useActiveAccount()
  const { setQuickBuyModalOrderRelayId } = useAccountOrCollectionPageContext()

  const order = useFragment(
    graphql`
      fragment AssetSearchListViewTableQuickBuy_order on OrderV2Type {
        maker {
          address
        }
        item {
          __typename
          chain {
            identifier
          }
          # eslint-disable-next-line relay/must-colocate-fragment-spreads we're using this
          ...itemEvents_dataV2
        }
        openedAt
        relayId
      }
    `,
    orderDataKey,
  )

  const isOwnOrder = addressesEqual(order.maker.address, activeAccount?.address)

  const listingTime = dateFromISO8601(order.openedAt)
  const isScheduledOrderInFuture = isAfter(listingTime, new Date())

  const scheduledOrderText = useScheduledOrderText(listingTime, "buy")

  let error = ""

  if (isOwnOrder) {
    error = t("quickBuy.errors.ownOrder", "You own this item.")
  } else if (isScheduledOrderInFuture) {
    error = scheduledOrderText
  }

  const disabled = Boolean(error)

  return (
    <NetworkUnsupportedGate
      chainIdentifier={order.item.chain.identifier}
      shouldAuthenticate
    >
      {({ handleIfNotSupported }) => (
        <Tooltip
          appendTo={IS_SERVER ? undefined : document.body}
          content={error}
          disabled={!error}
          maxWidth={200}
        >
          <Flex
            className={classNames(
              "items-center rounded-xl bg-component-gray-1 py-1.5 pl-2 pr-1 hover:bg-component-gray-2 active:bg-component-gray-3",
              disabled && "opacity-40",
            )}
            ref={ref}
            onClick={(
              event:
                | React.MouseEvent<HTMLDivElement>
                | React.MouseEvent<HTMLAnchorElement>,
            ) => {
              if (!disabled) {
                event.preventDefault()
                event.stopPropagation()

                handleIfNotSupported(() => {
                  setQuickBuyModalOrderRelayId(order.relayId)
                  trackClickQuickBuy()
                })()
              }
            }}
          >
            {children}
            <Icon className="text-blue-3" fill={1} size={22} value="bolt" />
          </Flex>
        </Tooltip>
      )}
    </NetworkUnsupportedGate>
  )
})

type Props = {
  order:
    | (AssetSearchListViewTableQuickBuy_order$key &
        useIsQuickBuyEnabled_order$key)
    | null
  price: ReactNode | null
}

const Empty = () => <span>{EMPTY_PRICE_DISPLAY}</span>

const _AssetSearchListViewTableQuickBuy = ({ order, price }: Props) => {
  const isQuickBuyEnabled = useIsQuickBuyEnabled(order ?? null)
  return isQuickBuyEnabled && order ? (
    <AssetSearchListTableQuickBuyButton
      data-testid="ItemCardPrice"
      order={order}
    >
      <>{price ?? <Empty />}</>
    </AssetSearchListTableQuickBuyButton>
  ) : (
    <>{price ?? <Empty />}</>
  )
}

export const AssetSearchListViewTableQuickBuy = memo(
  _AssetSearchListViewTableQuickBuy,
)
