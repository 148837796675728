/**
 * @generated SignedSource<<dfe164750a02d66d162f94d507ae15e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetSearchListViewTableAssetInfo_item$data = {
  readonly __typename: string;
  readonly pendingTxn: {
    readonly blockExplorerLink: string | null;
    readonly maxFeeGwei: string | null;
    readonly priorityFeeGwei: string | null;
    readonly seenAt: string | null;
    readonly status: string | null;
    readonly transactionHash: string | null;
  } | null;
  readonly saleOrListingCancellationOccurred: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableItemCellTooltip_item">;
  readonly " $fragmentType": "AssetSearchListViewTableAssetInfo_item";
};
export type AssetSearchListViewTableAssetInfo_item$key = {
  readonly " $data"?: AssetSearchListViewTableAssetInfo_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetSearchListViewTableAssetInfo_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetSearchListViewTableAssetInfo_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortfolioTableItemCellTooltip_item"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "pendingTxn",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxFeeGwei",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priorityFeeGwei",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "seenAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "transactionHash",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "blockExplorerLink",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "saleOrListingCancellationOccurred",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};

(node as any).hash = "0e80c31ec81a3ce0457805e77c214236";

export default node;
