import React from "react"
import { merge } from "lodash"
import { IS_SERVER } from "@/constants/environment"
import { Select, SelectOption, SelectProps } from "@/design-system/Select"

type Props<Column extends string = string> = {
  column: Column
  onSelect: (column: Column) => unknown
} & Omit<SelectProps<Column>, "value" | "onSelect"> & {
    title: string
  }

export const VirtualizedTableHeaderSelect = <Column extends string>({
  options,
  column,
  overrides,
  onSelect,
  title,
  ...props
}: Props<Column>) => {
  return (
    <Select
      bordered={false}
      clearable={false}
      hideOnScroll
      mobileTitle={title}
      options={options}
      overrides={merge(
        {
          Dropdown: {
            props: {
              appendTo: IS_SERVER ? undefined : document.body,
            },
          },
          ContentItem: {
            props: {
              height: "36px",
              style: { padding: "8px 0 8px 6px" },
            },
          },
          ContentLabelTitle: {
            props: {
              size: "small",
              className: "whitespace-nowrap",
            },
          },
        },
        overrides,
      )}
      readOnly
      value={column}
      variant="item"
      onSelect={(newValue: SelectOption<Column> | undefined) => {
        if (newValue?.value) {
          onSelect(newValue.value)
        }
      }}
      {...props}
    />
  )
}
