/* istanbul ignore file */
import React from "react"
import { Checkbox } from "@opensea/ui-kit"
import { Tooltip } from "@/design-system/Tooltip"
import { useItemAddToCart } from "@/features/shopping-cart/components/ItemAddToCartButton"
import {
  useSweepFormIsSweepModeToggled,
  useGetItemSweepStatus,
} from "@/features/sweep/SweepContextProvider.react"
import { ItemAddToCartButton_order$key } from "@/lib/graphql/__generated__/ItemAddToCartButton_order.graphql"

type Props = {
  order: ItemAddToCartButton_order$key
  itemRelayId: string | undefined
}

export const AssetSearchListViewTableAddToCart = ({
  order,
  itemRelayId,
}: Props) => {
  // Accessing context in leaf node of table row to avoid re-rendering entire
  // row component when context values change
  const isSweepModeToggled = useSweepFormIsSweepModeToggled()
  const getItemSweepStatus = useGetItemSweepStatus()
  const itemSweepStatus = getItemSweepStatus(itemRelayId)
  const isSelectedForSweep =
    isSweepModeToggled && itemSweepStatus.isMostProbableToSweep

  const { isOrderAlreadyAdded, error } = useItemAddToCart({
    order,
  })
  const isSelectedForCart = !isSweepModeToggled && isOrderAlreadyAdded

  // To avoid double click and state issues, No need to add onClick or
  // onChange as it is currently supported on the parent row. The parent row's
  // onClick handles the add to cart behaviour via ``onAddToCart()``. Previous
  // solution utilized preventDefault and stopPropagation but still didnt
  // stop the bubbling and double click.
  return (
    <Tooltip content={error} disabled={!error}>
      <Checkbox checked={isSelectedForCart || isSelectedForSweep} />
    </Tooltip>
  )
}
