import React, { memo, useCallback, useContext, useMemo } from "react"
import { Media, classNames, FlexColumn, Text } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import {
  ItemCard,
  ItemCardOnClickExtra,
} from "@/components/assets/ItemCard.react"
import { getCardVariantMinWidth } from "@/components/layout/AccountOrCollectionPage/components/AssetCardVariantToggle"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { GLOBAL_MAX_PAGE_SIZE, NO_SIMILAR_ITEMS_IMG } from "@/constants"
import { AssetItemsGrid } from "@/design-system/AssetItemsGrid/AssetItemsGrid.react"
import { Block } from "@/design-system/Block"
import { FeatureTable } from "@/design-system/FeatureTable"
import { StatsItem } from "@/design-system/FeatureTable/components/CollapsedRow.react"
import { FullRowContainer } from "@/design-system/FeatureTable/styles"
import { Gallery, GalleryProps, GalleryVariant } from "@/design-system/Gallery"
import { Image } from "@/design-system/Image"
import {
  TableRowRenderProps,
  VirtualizedTable,
  VirtualizedTablePaginationProps,
} from "@/design-system/VirtualizedTable"
import { AccountPageContext } from "@/features/account/components/AccountPage/tabs/utils"
import {
  useIsAssetSelected,
  useToggleAssetSelection,
} from "@/features/account/hooks/useAssetSelection"
import { readAssetSelectionItem } from "@/features/assets/hooks/useAssetSelectionStorage"
import { useShoppingCartActions } from "@/features/shopping-cart/utils/ShoppingCartContextProvider"
import { useIsLastProbableItemToSweep } from "@/features/sweep/hooks/useIsLastProbableItemToSweep"
import {
  useGetItemSweepStatus,
  useIsSweepPreviewHighlighted,
  useSweepFormIsSweepModeToggled,
  useSweepItemIneligibilityReasonMap,
} from "@/features/sweep/SweepContextProvider.react"
import { useIsVirtualizedTableViewEnabled } from "@/hooks/useFlag"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import {
  AssetSearchList_data$data,
  AssetSearchList_data$key,
} from "@/lib/graphql/__generated__/AssetSearchList_data.graphql"
import { ItemCard_data$data } from "@/lib/graphql/__generated__/ItemCard_data.graphql"
import { graphql, PageProps } from "@/lib/graphql/graphql"
import { flatMap } from "@/lib/helpers/array"
import { selectClassNames } from "@/lib/helpers/styling"
import { useClearSearchContext } from "../utils/SearchContextProvider.react"
import { AssetSearchListViewTableHeader } from "./AssetSearchListViewTable/AssetSearchListViewTableHeader"
import {
  AssetSearchListViewTableRow,
  NewAssetSearchListViewTableRow,
  NewAssetSearchListViewTableRowProps,
} from "./AssetSearchListViewTable/AssetSearchListViewTableRow"
import { AssetCardVariant } from "./AssetSearchView"

export type AssetSearchListAsset = AssetSearchList_data$data[number]

export type AssetSearchListOnClickExtra = ItemCardOnClickExtra & {
  index: number
}

export type AssetSearchListProps = {
  cardVariant?: AssetCardVariant
  fullWidth?: boolean
  className?: string
  data: AssetSearchList_data$key | null
  exclude?: string[]
  variant?: GalleryVariant
  singlePage?: boolean
  page?: PageProps
  pageSize: number
  accountAddress?: string
  selectOnlyMode?: boolean
  showQuantity?: boolean
  showContextMenu?: boolean
  showCollectionName?: boolean
  showAssetMediaEditions?: boolean
  showQuantityBadge?: boolean
  onLoadNewPage?: () => unknown
  pagination?: VirtualizedTablePaginationProps
} & Omit<AssetListItemProps, "accountAddress">

type AssetListItemProps = {
  showQuantity?: boolean
  showContextMenu?: boolean
  onClick?: (
    assetData: ItemCard_data$data,
    extra: AssetSearchListOnClickExtra,
  ) => void
  showCollectionName?: boolean
  selectOnlyMode?: boolean
  accountAddress: string | undefined
  fillContainerWidth?: boolean
  getAssetDisabledReason?: (asset: AssetSearchListAsset) => string | undefined
}

type AssetListItemComponentType = GalleryProps<
  {
    assetData: AssetSearchListAsset | null
    cardVariant: AssetCardVariant | undefined
    itemKey: string
  } & AssetListItemProps
>["renderItem"]

const _AssetListItem: AssetListItemComponentType = ({
  data,
  index,
  width,
  containerWidth,
  fillContainerWidth,
  showQuantityBadge,
  showAssetMediaEditions,
  sizes,
}) => {
  const {
    assetData: asset,
    accountAddress,
    showContextMenu,
    onClick,
    showCollectionName,
    selectOnlyMode,
    cardVariant,
    getAssetDisabledReason,
  } = data

  const { isItemInCart } = useShoppingCartActions()
  const isSweepModeToggled = useSweepFormIsSweepModeToggled()

  const getItemSweepStatus = useGetItemSweepStatus()
  const { itemIneligibilityReasonMap } = useSweepItemIneligibilityReasonMap()
  const isPreviewHighlighted = useIsSweepPreviewHighlighted()

  const itemSweepStatus = getItemSweepStatus(asset?.relayId)

  const isLastProbableItemToSweep = useIsLastProbableItemToSweep(asset?.relayId)

  const isSelected = useIsAssetSelected(asset?.relayId)

  const isHighlighted = isSweepModeToggled
    ? itemSweepStatus.isMostProbableToSweep
    : isItemInCart(asset?.relayId) || isSelected

  const disabledReason = asset
    ? isSweepModeToggled
      ? itemIneligibilityReasonMap.get(asset.relayId)
      : getAssetDisabledReason?.(asset)
    : undefined

  const isAssetDisabled = Boolean(disabledReason)

  const toggleAssetSelected = useToggleAssetSelection(
    asset ? readAssetSelectionItem(asset) : null,
  )

  const memodOnClick = useCallback(
    (assetData: ItemCard_data$data | null, { event }: ItemCardOnClickExtra) => {
      assetData && onClick?.(assetData, { event, index })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [index],
  )

  const className = selectClassNames("AssetSearchList", {
    asset: true,
  })

  return (
    <ItemCard
      accountAddress={accountAddress}
      className={className}
      containerWidth={containerWidth}
      data={asset}
      disabledReason={disabledReason}
      fillContainerWidth={fillContainerWidth}
      index={index}
      isDisabled={isAssetDisabled}
      isHighlighted={isHighlighted}
      isPreviewHighlighted={isPreviewHighlighted(asset?.relayId)}
      selectOnlyMode={selectOnlyMode}
      shouldScrollIntoViewWhenHighlightToggled={
        isSweepModeToggled && isLastProbableItemToSweep
      }
      showAssetMediaEditions={showAssetMediaEditions}
      showCollectionName={showCollectionName}
      showContextMenu={showContextMenu}
      showQuantityBadge={showQuantityBadge}
      sizes={sizes}
      toggleAssetSelected={toggleAssetSelected}
      variant={cardVariant}
      width={width}
      onClick={memodOnClick}
    />
  )
}

const AssetListItem = memo(_AssetListItem) as AssetListItemComponentType

type VirtualizedTableItem = NewAssetSearchListViewTableRowProps & {
  itemKey: string | undefined
}

const RenderRow = ({ item }: TableRowRenderProps<VirtualizedTableItem>) => {
  return <NewAssetSearchListViewTableRow {...item} />
}

const getItemKey = (item: VirtualizedTableItem | undefined) => item?.itemKey
const itemHeightEstimate = { min: 60 }

const EmptyComponent = () => null

const _AssetSearchList = ({
  className,
  data: dataKey,
  exclude,
  singlePage,
  variant,
  page,
  pageSize,
  cardVariant,
  onLoadNewPage,
  showAssetMediaEditions,
  fullWidth,
  showQuantityBadge,
  pagination: newPagination,
  accountAddress,
  ...assetMiscProps
}: AssetSearchListProps) => {
  const t = useTranslate("components")
  const data = useFragment(
    graphql`
      fragment AssetSearchList_data on ItemType
      @relay(plural: true)
      @argumentDefinitions(
        identity: { type: "IdentityInputType", defaultValue: {} }
        showContextMenu: { type: "Boolean", defaultValue: false }
        showQuantity: { type: "Boolean", defaultValue: false }
        showBestAskForOwner: { type: "Boolean", defaultValue: false }
        shouldShowBestBid: { type: "Boolean", defaultValue: false }
      ) {
        __typename
        relayId
        ...ItemCard_data
          @arguments(
            identity: $identity
            showContextMenu: $showContextMenu
            showQuantity: $showQuantity
            showBestAskForOwner: $showBestAskForOwner
            shouldShowBestBid: $shouldShowBestBid
          )
        ... on AssetType {
          collection {
            isVerified
            relayId
          }
          isErc721cFeeEnforced
        }
        ... on AssetBundleType {
          bundleCollection: collection {
            isVerified
            relayId
          }
        }
        chain {
          identifier
        }
        ...useAssetSelectionStorage_item
          @arguments(
            identity: $identity
            withBestAsk: $showBestAskForOwner
            withBestBid: $shouldShowBestBid
          )
      }
    `,
    dataKey,
  )

  const { exclude: accountsPageExclude } = useContext(AccountPageContext)
  const { sidebarOpen, isListViewEnabled } = useAccountOrCollectionPageContext()
  const isVirtualizedTableViewEnabled = useIsVirtualizedTableViewEnabled()

  const results = useMemo(() => {
    const resultRelayIds = new Set()
    return flatMap(
      (data || []).filter(data => {
        if (accountsPageExclude.includes(data.relayId)) {
          return false
        }

        if (exclude?.includes(data.relayId)) {
          return false
        }

        return true
      }),
      result => {
        const relayId = result.relayId
        if (!relayId || resultRelayIds.has(relayId)) {
          return []
        }
        resultRelayIds.add(relayId)
        return [result]
      },
    )
  }, [data, accountsPageExclude, exclude])

  // We add pageSize to show dummy loading skeletons when we still have more to load
  const showSkeletons = !singlePage && page?.hasMore()
  const numSkeletons = showSkeletons
    ? cardVariant === "list-view"
      ? 6
      : pageSize
    : 0

  const items = useMemo(() => {
    return Array(data ? results.length + numSkeletons : pageSize)
      .fill({})
      .map((_, i) => ({
        assetData: (results[i] ?? null) as (typeof results)[number] | null,
        accountAddress,
        cardVariant,
        itemKey: results[i]?.relayId,
        ...assetMiscProps,
      }))
    // Using spread operator as we're spreading over assetMiscProps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountAddress,
    data,
    cardVariant,
    numSkeletons,
    pageSize,
    results,
    // eslint-disable-next-line
    ...Object.values(assetMiscProps),
  ])

  const tableHeader = useMemo(() => {
    return <AssetSearchListViewTableHeader />
  }, [])

  const getKey = useCallback<
    GalleryProps<(typeof items)[number] | null>["getKey"]
  >((item, index) => {
    return item?.assetData?.relayId || index
  }, [])

  const isItemLoaded = useCallback(
    (index: number, currentItems: typeof items) => {
      return !!currentItems[index]
    },
    [],
  )

  const isItemLoadedCssGrid = useCallback(
    (index: number, currentItems: typeof items) => {
      return !!currentItems[index].assetData
    },
    [],
  )

  const pagination = useMemo(
    () =>
      singlePage || !page
        ? undefined
        : {
            disableLoader: true,
            page,
            size: pageSize,
            onLoad: onLoadNewPage,
          },
    [singlePage, page, pageSize, onLoadNewPage],
  )

  if (items.length === 0) {
    return (
      <FlexColumn
        className={classNames("h-[300px] justify-center pt-4", className)}
      >
        <Image
          alt="no similar items image"
          height={100}
          src={`https://opensea.io${NO_SIMILAR_ITEMS_IMG}`}
          width={100}
        />
        <Text asChild className="text-center">
          <p>{t("assetSearchList.noItemsToDisplay", "No items to display")}</p>
        </Text>
      </FlexColumn>
    )
  }

  const galleryProps: Omit<
    GalleryProps<(typeof items)[number]>,
    "itemMinWidth" | "gridGap"
  > = {
    evenSidePadding: singlePage,
    getKey,
    isItemLoaded,
    itemHeightEstimate: 500,
    items,
    pagination,
    renderItem: AssetListItem,
    variant,
    overscanBy: 1,
    threshold: pageSize,
    isLoading: !data,
  }

  if (isListViewEnabled && cardVariant === "list-view") {
    if (isVirtualizedTableViewEnabled && newPagination) {
      return (
        <VirtualizedTable
          header={<AssetSearchListViewTableHeader />}
          itemHeightEstimate={itemHeightEstimate}
          itemKey={getItemKey}
          items={items}
          overscanBy={3}
          renderRow={RenderRow}
          {...newPagination}
        />
      )
    }
    return (
      <TableScrollContainer>
        <TableContainer>
          <FeatureTable
            header={tableHeader}
            itemHeightEstimate={73}
            itemKey={getKey}
            items={items}
            key={String(sidebarOpen)}
            overscanBy={3}
            pagination={pagination}
            renderFullRow={AssetSearchListViewTableRow}
            renderMore={EmptyComponent}
            renderPrimary={AssetSearchListViewTableRow}
            role="table"
            showInteractiveStyles
            // Account for 6 placeholder rows
            threshold={12}
          />
        </TableContainer>
      </TableScrollContainer>
    )
  }

  if (variant === "grid" && cardVariant !== "natural") {
    return (
      <Block className={className}>
        <AssetItemsGrid
          getKey={getKey}
          isItemLoaded={isItemLoadedCssGrid}
          isReducedColumns={sidebarOpen && !fullWidth}
          items={items}
          pagination={pagination}
          renderItem={AssetListItem}
          showAssetMediaEditions={showAssetMediaEditions}
          showQuantityBadge={showQuantityBadge}
          variant={cardVariant || "cozy"}
        />
      </Block>
    )
  }

  return (
    <Block className={className}>
      <Media lessThan="sm">
        <Gallery
          {...galleryProps}
          gridGap={8}
          itemMinWidth={getCardVariantMinWidth(
            "mobile",
            cardVariant ?? "compact",
          )}
        />
      </Media>
      <Media greaterThanOrEqual="sm">
        <Gallery
          {...galleryProps}
          gridGap={16}
          itemMinWidth={getCardVariantMinWidth(
            "desktop",
            cardVariant ?? "cozy",
          )}
        />
      </Media>
    </Block>
  )
}

const TableScrollContainer = styled(Block)`
  width: 100%;
  overflow-x: auto;
`

const TableContainer = styled(Block)`
  min-width: fit-content;
  ${StatsItem}, ${FullRowContainer} {
    padding: 0;
    cursor: default;
    border-radius: 0;
  }
`

type AssetSearchListSkeletonProps = {
  cardVariant: AssetCardVariant | undefined
  pageSize?: number
  variant?: GalleryVariant
}

const AssetSearchListSkeleton = ({
  cardVariant,
  pageSize = GLOBAL_MAX_PAGE_SIZE,
  variant = "grid",
}: AssetSearchListSkeletonProps) => {
  const clearSearchContext = useClearSearchContext()

  useMountEffect(() => {
    clearSearchContext()
  })

  return (
    <AssetSearchList
      cardVariant={cardVariant}
      data={null}
      pageSize={pageSize}
      variant={variant}
    />
  )
}

export const AssetSearchList = Object.assign(memo(_AssetSearchList), {
  Skeleton: AssetSearchListSkeleton,
})
