import React from "react"
import { useFragment } from "react-relay"
import { AssetQuantity_data$key } from "@/lib/graphql/__generated__/AssetQuantity_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { Price, PriceProps } from "./Price.react"

type AssetQuantityProps = Pick<
  PriceProps,
  | "symbolVariant"
  | "variant"
  | "secondary"
  | "maxDecimalPlaces"
  | "className"
  | "fontWeight"
  | "position"
  | "compactDisplay"
  | "noTooltip"
> & {
  data: AssetQuantity_data$key
  mapQuantity?: (quantity: BigNumber) => BigNumber // Post-process the quantity value in the data.
}

/**
 * @deprecated AssetQuantityType is a broken abstraction. Use OrderPrice component instead.
 */
export const AssetQuantity = ({
  data: dataKey,
  mapQuantity,
  ...priceProps
}: AssetQuantityProps) => {
  const { asset, quantity } = useFragment(
    graphql`
      fragment AssetQuantity_data on AssetQuantityType {
        asset {
          ...Price_data
        }
        quantity
      }
    `,
    dataKey,
  )

  return (
    <Price
      {...priceProps}
      data={asset}
      quantity={bn(mapQuantity ? mapQuantity(bn(quantity)) : quantity)}
    />
  )
}
