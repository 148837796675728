import React, { forwardRef } from "react"
import { classNames } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { Flex, FlexProps } from "@/design-system/Flex"
import { VirtualizedTableHeaderCell } from "@/design-system/VirtualizedTable/components/VirtualizedTableHeaderCell.react"
import { VirtualizedTableHeaderSelect } from "@/design-system/VirtualizedTable/components/VirtualizedTableHeaderSelect.react"
import { DEFAULT_HEADER_HEIGHT_PX } from "@/design-system/VirtualizedTable/constants"

export type VirtualizedTableHeaderProps = {
  className?: string
  children: React.ReactNode
  gap?: number
  /**
   * Set to a number to make the header vertically sticky, prefer instead of
   * `position: sticky;` and `top: 0`.
   */
  stickyTop?: number
} & FlexProps

const VirtualizedTableHeaderBase = forwardRef<
  HTMLDivElement,
  VirtualizedTableHeaderProps
>(function VirtualizedTableHeader(
  {
    className,
    children,
    gap,
    height = DEFAULT_HEADER_HEIGHT_PX,
    stickyTop,
    ...flexProps
  }: VirtualizedTableHeaderProps,
  ref,
) {
  return (
    <Container
      $stickyTop={stickyTop}
      className={classNames("z-[1]", className)}
      gap={gap}
      height={height}
      ref={ref}
      role="row"
      {...flexProps}
    >
      {children}
    </Container>
  )
})

export const VirtualizedTableHeader = Object.assign(
  VirtualizedTableHeaderBase,
  {
    Cell: VirtualizedTableHeaderCell,
    Select: VirtualizedTableHeaderSelect,
  },
)

const Container = styled(Flex)<{ $stickyTop?: number; gap?: number }>`
  background-color: ${props => props.theme.colors.base1};
  border-bottom: 1px solid
    ${props => props.theme.colors.components.border.level2};

  // Ensure container spans the entire width of its contents to ensure sticky
  // columns do not lose stickiness on small viewports..
  display: inline-flex;
  min-width: 100%;

  overflow: auto;

  // Hide scrollbar in Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }
  // Hide scrollbar in Firefox
  scrollbar-width: none;
  -ms-overflow-style: none;

  ${props =>
    props.gap
      ? css`
          gap: ${props.gap}px;
        `
      : ""}

  ${props =>
    props.$stickyTop !== undefined
      ? css`
          position: sticky;
          top: ${props.$stickyTop}px;
        `
      : ""}
`
